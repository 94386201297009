@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700&family=Open+Sans&display=swap');
@import "~bootstrap/scss/bootstrap";
@import '~flag-icon-css/sass/flag-icons';

$primary: #5e72e4;
$primary-dark: darken($primary, 30%);

$breakpoint-s: 576px;
$breakpoint-m: 768px;
$breakpoint-l: 992px;
$breakpoint-xl: 1200px;

@import 'header';
@import 'content';
@import 'footer';

.animate {
    transition: 0.3s ease-in-out all;
}

p, a {
    font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6, .display-1, .display-2, .display-3, .display-4 {
    font-family: "Montserrat", sans-serif;
    color: $primary;
}

.flag-icon {
    border-radius: 3px;
}

.heading-line {
    &:after {
        content: ' ';
        display: block;
        margin: 20px 0;
        height: 6px;
        width: 64px;
        background: $primary;
    }
}

.page-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $primary;
}

.lead {
    font-family: "Montserrat", sans-serif;
}

.secondary {
    font-family: "Montserrat", sans-serif;
    color: $primary-dark;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: $font-size-lg;
}

.btn .spinner-border {
    display: inline-block;
    width: 16px;
    height: 16px;
}
