.headroom {
    @extend .animate;
    width: 100%;
    display: block;
    z-index: 999;
    padding: 0;

    position: fixed;
}

.headroom--top, .headroom--top nav {
    background: transparent !important;
}

.headroom--pinned {
    transform: initial;
    background: $primary;

    nav {
        padding: 12px 24px;
    }
}

.navbar-brand {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: .05px;
    vertical-align: center;
    padding: 9px;
    display: inline-block;
}

// scroll down
.headroom--unpinned {
    transform: initial;
    background: $primary;

    nav {
        padding: 16px 24px !important;
    }
}

.headroom--not-top {
    @extend .shadow, .navbar-dark;
}

.navbar-collapse {
    float: right;
}

nav {
    @extend .animate;
    padding: 12px 24px !important;

    a.nav-link {
        color: $gray-700 !important;
        padding: 8px 24px !important;
    }
}

.navbar-dark a.nav-link, .navbar-dark .navbar-brand {
    color: $gray-100 !important;
}

.banner {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    //clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);

    @media (min-width: $breakpoint-m) {
        background-position-x: 35%;
    }
}

.banner-heading {
    position: absolute;
    width: 50%;
    padding: 24px;
    top: 15%;

    @media (min-width: $breakpoint-m) {
        position: absolute;
        width: 33%;
        top: 33%;
        right: 5%;
    }

    .banner-heading-iam {
        @extend .secondary;
        margin: 0;
    }

    .banner-heading-name {
        @extend .display-4, .heading-line;
        font-weight: 600;
    }

    .banner-heading-subtitle {
        @extend .lead;
        margin: 0 0 15px 0;
    }

    .banner-description {
        text-align: justify;
        display: none;

        @media (min-width: $breakpoint-m) {
            display: inherit;
        }
    }
}

.banner-scroll-button {
    @extend .animate;
    font-size: $font-size-lg;
    color: $primary;
    position: absolute;
    left: 50%;
    bottom: 10px;
    cursor: pointer;

    &:hover {
        bottom: 20px;
        color: lighten($primary, 30%);
    }
}
